import { MutableRefObject, useEffect, useRef, useState } from 'react';

import L, { GeoJSONOptions, LatLngExpression, Map } from 'leaflet';
import { Feature, Geometry, MultiPolygon } from 'geojson';

import { Box, Divider, List, ListItem, Typography } from '@mui/material';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';
import './AnalyticsReport.module.css';
import brazil_geojson from '@spot-spotesg/modules/analytics/constants/brazil_states_geojson.json';
import { center, centroid } from '@turf/turf';
import React from 'react';
import { ReportData } from '../constants/report_type';
import { flow, get, result } from 'lodash';
import analysis from '../store/analysis';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from '@spot/shared-hooks/useQuery.hook';
import getStyleByYear from '@spot-spotesg/utils/getStyleByYear.util';
import {
  formatDocument,
  isEmbargoConservationAndIndigena,
  isListasInapto,
  isPRODESInapto,
  unnormalizeCarCode,
} from '../type_validators/report_validators';

function LegendItem({ label, color }: { label: string; color: string }) {
  return (
    <Box display={'flex'} gap={1}>
      <Box
        width={'20px'}
        height={'12px'}
        borderRadius={1}
        bgcolor={`${color}aa`}
        border={`2px solid ${color}`}
      ></Box>
      <Typography variant="caption">{label}</Typography>
    </Box>
  );
}

interface LegendItem {
  type: string;
  year: string;
}

export default function AnalyticsReportScreen() {
  const mapRef: MutableRefObject<Map | null> = useRef<Map | null>(null);
  const minimapRef: MutableRefObject<Map | null> = useRef<Map | null>(null);
  const [ready, setReady] = useState(false);

  const queryParams = useQuery();
  const car_code = queryParams.get('carCode');
  const dispatch = useDispatch();

  const dispatchRedux = {
    get: flow(analysis.action.serviceReportGet, dispatch),
  };

  const selectorRedux = {
    loading: useSelector(analysis.selector.selectLoading),
    data: useSelector(analysis.selector.selectData) as ReportData,
  };

  const isAllApto = (selectorRedux.data?.results?.results || []).every(
    (result) => result.apto === 'APTO'
  );

  const compliances_results = selectorRedux.data?.results?.results || [];

  const pattern = /(\d{4}):\s*([\d.]+)\s*ha/;

  const inaptos_rest = compliances_results
    .filter(isEmbargoConservationAndIndigena)
    .map((result) => {
      let name;

      if (result.type === 'Embargos do Ibama') {
        name = 'Embargos IBAMA';
      } else if (result.type === 'Embargos do Semas') {
        name = 'Embargos SEMAS';
      } else {
        name = result.type;
      }

      return `${name}: ${result.area_ha.toFixed(
        2
      )} ha - ${result.area_percentage.toFixed(2)}%`;
    });

  const inaptos_lists = compliances_results
    .filter(isListasInapto)
    .map((result) => `${result.type}`);

  const inaptos_years_prodes = compliances_results
    .filter(isPRODESInapto)
    .map((result) => {
      const splitted_years = result.years
        .substring(0, result.years.length - 2)
        .split(',');

      const years = splitted_years
        .map((year) => {
          const match = year.trim().match(pattern);
          if (!match) return null;

          let type: string = result.type;

          const ha_area = match[2];

          if (type === 'Desmatamento Ilegal (PRODES)') {
            type = 'Desmatamento Ilegal';
          }

          if (type === 'Desmatamento Zero (PRODES)') {
            type = 'Desmatamento Zero';
          }

          if (Number(ha_area || 0) > 6.25) {
            return `${type} ${year.trim()}`;
          }

          return null;
        })
        .filter((year) => year !== null);

      return years;
    })
    .flat();

  const legendItems = (selectorRedux.data?.results?.compliances || []).map(
    (compliance) => {
      const areaHa = compliance.area_ha.toFixed(2);
      const areaPercentage = compliance.area_percentage.toFixed(2);
      const year =
        compliance.type_area === 'deforestation' ? compliance.year : undefined;

      return {
        type: compliance.type_area,
        year: `${year ? year + ': ' : ''}${areaHa} ha - ${areaPercentage}%`,
      };
    }
  );

  const consolidatedLegendItems = legendItems.reduce(
    (acc: LegendItem[], curr) => {
      const typesToConsolidate = [
        'ibama',
        'conservation',
        'semas-manual',
        'semas-auto',
        'indigenous',
      ];

      if (curr.type === 'deforestation') {
        const year = curr.year.split(':')[0];

        const existingItem = acc.find(
          (item) => item.type === 'deforestation' && item.year.startsWith(year)
        );

        if (existingItem) {
          const [existingHa, existingPercentage] = existingItem.year
            .split(': ')[1]
            .replace(' ha', '')
            .split(' - ')
            .map((value) => parseFloat(value.replace(',', '.')));

          const [currentHa, currentPercentage] = curr.year
            .split(': ')[1]
            .replace(' ha', '')
            .split(' - ')
            .map((value) => parseFloat(value.replace(',', '.')));

          const newHa = (existingHa + currentHa).toFixed(2);
          const newPercentage = (
            existingPercentage + currentPercentage
          ).toFixed(2);

          existingItem.year = `${year}: ${newHa} ha - ${newPercentage}%`;
        } else {
          acc.push({ ...curr });
        }
      } else if (typesToConsolidate.includes(curr.type)) {
        const existingItem = acc.find((item) => item.type === curr.type);

        if (existingItem) {
          const [existingHa, existingPercentage] = existingItem.year
            .replace(' ha', '')
            .split(' - ')
            .map((value) => parseFloat(value.replace(',', '.')));

          const [currentHa, currentPercentage] = curr.year
            .replace(' ha', '')
            .split(' - ')
            .map((value) => parseFloat(value.replace(',', '.')));

          const newHa = (existingHa + currentHa).toFixed(2);
          const newPercentage = (
            existingPercentage + currentPercentage
          ).toFixed(2);

          existingItem.year = `${newHa} ha - ${newPercentage}%`;
        } else {
          acc.push({ ...curr });
        }
      } else {
        acc.push({ ...curr });
      }
      return acc;
    },
    [] as LegendItem[]
  );

  const legendItemsGroupedByType = consolidatedLegendItems.reduce(
    (acc, curr) => {
      if (!acc[curr.type]) {
        acc[curr.type] = [];
      }
      acc[curr.type].push(curr);
      return acc;
    },
    {} as Record<string, typeof legendItems>
  );

  const sortedLegendItems = Object.entries(legendItemsGroupedByType).sort(
    (a, b) => {
      if (a[0] === 'deforestation') return -1;
      if (b[0] === 'deforestation') return 1;
      return 0;
    }
  );

  const stateCenters: Record<
    string,
    { center: LatLngExpression; zoom: number }
  > = {
    AC: { center: [-8.650396919500862, -70.452689469481], zoom: 5 },
    AL: { center: [-9.392289641106602, -36.45404537365879], zoom: 4 },
    AP: { center: [1.4700117698721051, -51.89743123128872], zoom: 5 },
    AM: { center: [-3.283041205252912, -64.49719186481259], zoom: 4 },
    BA: { center: [-12.201582894287998, -41.53498203248832], zoom: 4 },
    CE: { center: [-4.910747402800968, -39.52965149641235], zoom: 4 },
    DF: { center: [-15.76111974703073, -47.775203365379014], zoom: 6 },
    ES: { center: [-19.601424110848296, -40.477884610206246], zoom: 4 },
    GO: { center: [-16.067871600526757, -49.549326877349685], zoom: 4 },
    MA: { center: [-5.026065930844217, -45.15954912020712], zoom: 4 },
    MT: { center: [-12.646927864128477, -55.915612161738956], zoom: 4 },
    MS: { center: [-20.09225870185447, -54.615799070707304], zoom: 4 },
    MG: { center: [-18.47624025534489, -44.35711172550235], zoom: 4 },
    PA: { center: [-3.4168, -52.4398], zoom: 4 },
    PB: { center: [-7.184352246266856, -36.63210435218576], zoom: 4 },
    PR: { center: [-24.454028862146018, -51.55985385451597], zoom: 5 },
    PE: { center: [-8.34681928019639, -37.784893453403065], zoom: 4 },
    PI: { center: [-7.475242286411756, -42.81064153312978], zoom: 4 },
    RJ: { center: [-22.1574742203568, -42.46315584441604], zoom: 4 },
    RN: { center: [-5.722447667106912, -36.60243291926204], zoom: 4 },
    RS: { center: [-29.50483403823148, -53.15702885477063], zoom: 4 },
    RO: { center: [-10.473422, -62.761103], zoom: 5 },
    RR: { center: [1.9755646111897844, -61.18955595656329], zoom: 5 },
    SC: { center: [-27.075902863730878, -50.47803892998285], zoom: 5 },
    SP: { center: [-22.248255646159922, -48.882725767181675], zoom: 4 },
    SE: { center: [-10.598269877039446, -37.32447308663027], zoom: 4 },
    TO: { center: [-9.026547050574276, -48.291275949099635], zoom: 4 },
  };

  const stateNameToAbbreviation: Record<string, string> = {
    Acre: 'AC',
    Alagoas: 'AL',
    Amapá: 'AP',
    Amazonas: 'AM',
    Bahia: 'BA',
    Ceará: 'CE',
    'Distrito Federal': 'DF',
    'Espírito Santo': 'ES',
    Goiás: 'GO',
    Maranhão: 'MA',
    'Mato Grosso': 'MT',
    'Mato Grosso do Sul': 'MS',
    'Minas Gerais': 'MG',
    Pará: 'PA',
    Paraíba: 'PB',
    Paraná: 'PR',
    Pernambuco: 'PE',
    Piauí: 'PI',
    'Rio de Janeiro': 'RJ',
    'Rio Grande do Norte': 'RN',
    'Rio Grande do Sul': 'RS',
    Rondônia: 'RO',
    Roraima: 'RR',
    'Santa Catarina': 'SC',
    'São Paulo': 'SP',
    Sergipe: 'SE',
    Tocantins: 'TO',
  };

  const abbreviationToStateName: Record<string, string> = {
    AC: 'do Acre',
    AL: 'do Alagoas',
    AP: 'do Amapá',
    AM: 'do Amazonas',
    BA: 'da Bahia',
    CE: 'do Ceará',
    DF: 'do Distrito Federal',
    ES: 'do Espírito Santo',
    GO: 'do Goiás',
    MA: 'do Maranhão',
    MT: 'do Mato Grosso',
    MS: 'do Mato Grosso do Sul',
    MG: 'de Minas Gerais',
    PA: 'do Pará',
    PB: 'do Paraíba',
    PR: 'do Paraná',
    PE: 'do Pernambuco',
    PI: 'de Piauí',
    RJ: 'do Rio de Janeiro',
    RN: 'do Rio Grande do Norte',
    RS: 'do Rio Grande do Sul',
    RO: 'de Rondônia',
    RR: 'de Roraima',
    SC: 'de Santa Catarina',
    SP: 'de São Paulo',
    SE: 'de Sergipe',
    TO: 'de Tocantins',
  };

  const legendsTitle: Record<string, string> = {
    deforestation: 'PRODES',
    ibama: 'IBAMA',
    conservation: 'Un. de Conservação',
    'semas-manual': 'SEMAS',
    'semas-auto': 'SEMAS',
    indigenous: 'Terra Indígena',
  };

  const legendColors = {
    conservation: '#E0E0E0',
    'semas-manual': '#A25CCF',
    'semas-auto': '#A25CCF',
    ibama: '#FFC857',
    indigenous: '#807D92',
  };

  useEffectOnce(() => {
    if (car_code) {
      dispatchRedux.get(car_code);
    }

    const map: Map = L.map('map', {
      zoomControl: false,
      dragging: true,
      scrollWheelZoom: true,
      doubleClickZoom: false,
      attributionControl: false,
    }).setView([0, 0], 16);

    const minimap: Map = L.map('minimap', {
      zoomControl: false,
      dragging: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      attributionControl: false,
    }).setView([0, 0], 4);

    L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 20,
      }
    ).addTo(map);

    L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
    ).addTo(minimap);

    mapRef.current = map;
    minimapRef.current = minimap;
  });

  useEffect(() => {
    if (!minimapRef.current) return;
    if (!mapRef.current) return;
    if (!selectorRedux.data?.results?.geom) return;
    if (!selectorRedux.data?.state) return;

    const interval = setInterval(() => {
      setReady(true);
      clearInterval(interval);
    }, 3000);

    const map = mapRef.current;
    const minimap = minimapRef.current;

    const state = selectorRedux.data.state;
    const stateInfo = stateCenters[state];

    const geom_options: GeoJSONOptions = {
      style: function () {
        return {
          color: '#6471FD',
          fillOpacity: 0.3,
          stroke: true,
          weight: 2,
          fillColor: '#6471FD',
        };
      },
    };

    (selectorRedux.data?.results?.compliances || []).forEach((compliance) => {
      let style;

      if (compliance.type_area === 'deforestation') {
        style = getStyleByYear(String(compliance.year));
      } else {
        style = {
          color: legendColors[compliance.type_area] || 'black',
          bgOpacity: legendColors[compliance.type_area] + '4D',
          fillOpacity: 0.3,
          stroke: true,
        };
      }

      L.geoJSON(compliance.compliance_geometry, {
        style: {
          ...style,
          weight: 2,
        },
      }).addTo(map);
    });

    const geoJsonLayer = L.geoJSON(
      selectorRedux.data?.results?.geom as any,
      geom_options
    ).addTo(map);
    const geoJsonCenter = geoJsonLayer.getBounds().getCenter();
    const layerBounds = geoJsonLayer.getBounds();

    map.fitBounds(layerBounds, {
      animate: true,
      maxZoom: 17,
      paddingTopLeft: [0, 180],
    });

    const brazil_options: GeoJSONOptions = {
      onEachFeature: function (feature: Feature<Geometry, any>) {
        const featureCentroid = center(feature.geometry as any);
        const centroid_coordinates: LatLngExpression = [
          featureCentroid.geometry.coordinates[1],
          featureCentroid.geometry.coordinates[0],
        ];

        L.popup({
          closeButton: false,
          closeOnClick: false,
          closeOnEscapeKey: false,
          offset: [10, 40],
        })
          .setLatLng(centroid_coordinates)
          .setContent(
            selectorRedux.data?.state ==
              stateNameToAbbreviation[feature.properties.name]
              ? feature.properties.name
              : ''
          )
          .addTo(minimap);
      },
      style: function () {
        return {
          color: 'white',
          fillOpacity: 0.1,
          stroke: true,
          weight: 1,
          fillColor: 'white',
        };
      },
    };

    L.geoJSON(brazil_geojson as any, brazil_options).addTo(minimap);

    L.circle(geoJsonCenter, {
      color: 'red',
      weight: 5,
      opacity: 1,
      radius: 4000,
      fillColor: 'red',
      fillOpacity: 1,
    }).addTo(minimap);

    minimap.setView(stateInfo.center, stateInfo.zoom);
  }, [selectorRedux.data?.results?.geom]);

  return (
    <Box
      display={'flex'}
      // justifyContent={'center'}
      // alignItems={'center'}
      width={'auto'}
      minHeight={'auto'}
      marginRight={5}
    >
      {ready && <div className="ready-to-download"></div>}
      <Box
        // maxWidth={'100%'}
        // width={'297mm'}
        height={'206mm'}
        maxHeight={'206mm'}
        bgcolor={'white'}
        p={1}
        display={'grid'}
        gridTemplateColumns={'450px 1fr'}
        columnGap={2}
        className="container-report"
      >
        <Box position={'relative'} width={'450px'} height={'100%'}>
          <Box id="map" width={'100%'} height={'100%'} borderRadius={1}></Box>
          <Box
            id="minimap"
            width={'220px'}
            height={'130px'}
            borderRadius={1}
            position={'absolute'}
            top={10}
            right={10}
            boxShadow={'0 0 16px rgba(0, 0, 0, 0.7)'}
            zIndex={999}
            border={'1px solid #B3B3B3'}
          ></Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box>
            <Typography variant="caption">
              Data de geração: {new Date().toLocaleDateString('pt-BR')}
            </Typography>
            <Typography variant="body1">
              Estado {abbreviationToStateName[selectorRedux.data?.state]} e
              Ministério Público Federal - Auditoria para Cumprimento
            </Typography>
            <Typography variant="h1" fontSize={'18px'} fontWeight={'bold'}>
              Termo de Ajustamento de Conduta - TAC
            </Typography>
          </Box>
          <Divider />
          <Box>
            <Typography variant="body2" fontWeight={'bold'}>
              Proprietário:
            </Typography>
            <Box
              display={'grid'}
              gridTemplateColumns={'1fr 1fr'}
              gridTemplateRows={'minmax(48px, auto)'}
              gap={1}
            >
              <Box
                paddingBlock={0.5}
                paddingInline={1.5}
                borderRadius={'8px'}
                border={'1px solid #E6E6E6'}
                bgcolor={'#EEEEEE'}
              >
                <Typography
                  variant="body1"
                  fontSize={'12px'}
                  fontWeight={'bold'}
                >
                  Nome
                </Typography>
                <Typography variant="body1" fontSize={'14px'}>
                  {selectorRedux.data?.productor_name.toUpperCase() ||
                    'Não consta'}
                </Typography>
              </Box>
              <Box
                paddingBlock={0.5}
                paddingInline={1.5}
                borderRadius={'8px'}
                border={'1px solid #E6E6E6'}
                bgcolor={'#EEEEEE'}
              >
                <Typography
                  variant="body1"
                  fontSize={'12px'}
                  fontWeight={'bold'}
                >
                  Documento
                </Typography>
                <Typography variant="body1" fontSize={'14px'}>
                  {formatDocument(selectorRedux.data?.document) || 'Não consta'}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="body2" fontWeight={'bold'}>
              Propriedade:
            </Typography>
            <Box
              display={'grid'}
              gridTemplateColumns={'1fr 1fr'}
              gridTemplateRows={'minmax(48px, auto)'}
              gap={1}
            >
              <Box
                paddingBlock={0.5}
                paddingInline={1.5}
                borderRadius={'8px'}
                border={'1px solid #E6E6E6'}
                bgcolor={'#EEEEEE'}
              >
                <Typography
                  variant="body1"
                  fontSize={'12px'}
                  fontWeight={'bold'}
                >
                  Nome
                </Typography>
                <Typography variant="body1" fontSize={'14px'}>
                  {selectorRedux.data?.farm_name.toUpperCase() || 'Não consta'}
                </Typography>
              </Box>
              <Box
                paddingBlock={0.5}
                paddingInline={1.5}
                borderRadius={'8px'}
                border={'1px solid #E6E6E6'}
                bgcolor={'#EEEEEE'}
              >
                <Typography
                  variant="body1"
                  fontSize={'12px'}
                  fontWeight={'bold'}
                >
                  CAR
                </Typography>
                <Typography variant="body1" fontSize={'14px'}>
                  {unnormalizeCarCode(selectorRedux.data?.car_code)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="body1" fontWeight={'bold'}>
              Área:
            </Typography>
            <Box
              display={'grid'}
              gridTemplateColumns={'1fr 1fr'}
              gridTemplateRows={'minmax(48px, auto)'}
              gap={1}
            >
              <Box
                paddingBlock={0.5}
                paddingInline={1.5}
                borderRadius={'8px'}
                border={'1px solid #E6E6E6'}
                bgcolor={'#EEEEEE'}
              >
                <Typography
                  variant="body1"
                  fontSize={'12px'}
                  fontWeight={'bold'}
                >
                  Tamanho em hectares
                </Typography>
                <Typography variant="body1" fontSize={'14px'}>
                  {selectorRedux.data?.results?.area_ha.toFixed(2) + ' ha' ||
                    'N/A'}
                </Typography>
              </Box>
              <Box
                paddingBlock={0.5}
                paddingInline={1.5}
                borderRadius={'8px'}
                border={'1px solid #E6E6E6'}
                bgcolor={'#EEEEEE'}
              >
                <Typography
                  variant="body1"
                  fontSize={'12px'}
                  fontWeight={'bold'}
                >
                  Município
                </Typography>
                <Typography variant="body1" fontSize={'14px'}>
                  {selectorRedux.data?.metadata?.Município ||
                    'MUNICÍPIO NÃO ENCONTRADO'}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography variant="body1" fontWeight={'bold'}>
              Legenda:
            </Typography>
            <Box
              display={'grid'}
              gridTemplateRows={'repeat(6, 24px)'}
              gridAutoFlow={'column'}
              gridAutoColumns={'200px'}
            >
              <LegendItem label="Área do Imóvel" color="#6471FD" />
              {sortedLegendItems.map((item, index) => (
                <React.Fragment key={index}>
                  <Typography
                    variant="body1"
                    fontSize={'12px'}
                    fontWeight={'bold'}
                  >
                    {legendsTitle[item[0]] || item[0]}
                  </Typography>
                  {legendItemsGroupedByType[item[0]].map((item, index) => {
                    if (item.type !== 'deforestation') return null;
                    const color_year = item.year.split(':')[0] || '';

                    return (
                      <LegendItem
                        key={index}
                        label={item.year}
                        color={getStyleByYear(color_year).color}
                      />
                    );
                  })}
                  {legendItemsGroupedByType[item[0]].map((item, index) => {
                    if (item.type === 'deforestation') return null;

                    return (
                      <LegendItem
                        key={index}
                        label={item.year}
                        color={legendColors[item.type]}
                      />
                    );
                  })}
                </React.Fragment>
              ))}
            </Box>
          </Box>
          <Box>
            <Typography variant="body1" fontWeight={'bold'}>
              Resultados:
            </Typography>
            <Box
              display={'grid'}
              gridTemplateRows={'repeat(5, 24px)'}
              gridAutoFlow={'column'}
              gridAutoColumns={'320px'}
            >
              {isAllApto && (
                <List>
                  <ListItem>
                    <Typography variant="body1" fontSize={'12px'}>
                      APTO
                    </Typography>
                  </ListItem>
                </List>
              )}
              {!isAllApto && (
                <React.Fragment>
                  {inaptos_years_prodes.map((year, index) => (
                    <List key={index}>
                      <ListItem style={{ padding: 0 }}>
                        <Typography variant="body1" fontSize={'12px'}>
                          INAPTO: {year}
                        </Typography>
                      </ListItem>
                    </List>
                  ))}
                  {inaptos_lists.map((result, index) => (
                    <List key={index}>
                      <ListItem style={{ padding: 0 }}>
                        <Typography variant="body1" fontSize={'12px'}>
                          INAPTO: {result}
                        </Typography>
                      </ListItem>
                    </List>
                  ))}
                  {inaptos_rest.map((result, index) => (
                    <List key={index}>
                      <ListItem style={{ padding: 0 }}>
                        <Typography variant="body1" fontSize={'12px'}>
                          INAPTO: {result}
                        </Typography>
                      </ListItem>
                    </List>
                  ))}
                </React.Fragment>
              )}
            </Box>
          </Box>
          <Divider sx={{ mt: 'auto' }} />
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={4}
          >
            <Box>
              <Typography variant="body2" fontSize={'12px'} flex={1}>
                DIREITOS RESERVADOS LEI 5772 DE 21/12/71 A SPOTSAT.
                <br />
                Reprodução ou execução no seu todo ou parte deste projeto, sem a
                prévia autorização de seu autor, ficará sujeita as medidas
                legais cabíveis.
                <br /> As alterações deste projeto eximem o autor de quaisquer
                responsabilidades posteriores.
              </Typography>
              <Typography variant="body2" fontSize={'12px'}>
                Sistema de Referência de Coordenadas: Geográfico, WGS84. EPSG:
                4326 <br />
                CAR - Cadastro Ambiental Rural; PRODES - 2008/2024; IBAMA -
                Áreas Embargadas; FUNAI: Terra Indígena; IBGE - Unidades de
                Conservação
              </Typography>
            </Box>
            <Box display={'flex'} gap={1}>
              <img
                width={'150px'}
                height={'50px'}
                src={process.env.PUBLIC_URL + '/img/logo-spotesg.png'}
                alt="Logo SPOTESG"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
