import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ANALYTICS_ROUTES from './constants/analyticsRoutes.constant';

import useProtectedByAuth from '@spot-spotesg/hooks/useProtectedByAuth.hook';

import DrawerSendAnalyticsComponent from './components/DrawerSendAnalytics.component';

import AnalyticsScreen from './screens/Analytics.screen';
import AnalyticsIdScreen from './screens/AnalyticsId.screen';
import AnalyticsIdPrintScreen from './screens/AnalyticsIdPrint.screen';
import AnalyticsIdValidationScreen from './screens/AnalyticsIdValidation.screen';
import AnalyticsValidationPrintScreen from './screens/AnalyticsValidationPrint.screen';
import AnalyticsReportScreen from './screens/AnalyticsReport.screen';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';
import useAuth from '@spot/shared-hooks/useAuth.hook';

const AnalyticsModule = () => {
  const { signedIn } = useAuth();

  useProtectedByAuth();

  const dispatch = useDispatch();

  useEffectOnce(() => {
    if (signedIn) {
      //
    }
  });

  return (
    <>
      <Switch>
        <Route exact path={ANALYTICS_ROUTES.ROOT} component={AnalyticsScreen} />

        <Route
          exact
          path={ANALYTICS_ROUTES.ID_VALIDATION_PRINT()}
          component={AnalyticsValidationPrintScreen}
        />

        <Route
          exact
          path={ANALYTICS_ROUTES.ID()}
          component={AnalyticsIdScreen}
        />
        <Route
          exact
          path={ANALYTICS_ROUTES.ID_PRINT()}
          component={AnalyticsIdPrintScreen}
        />
        <Route
          exact
          path={ANALYTICS_ROUTES.ID_VALIDATION()}
          component={AnalyticsIdValidationScreen}
        />
        <Route
          exact
          component={AnalyticsReportScreen}
          path={ANALYTICS_ROUTES.REPORT}
        />
      </Switch>

      <DrawerSendAnalyticsComponent />
    </>
  );
};

export default AnalyticsModule;
