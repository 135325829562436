import fetch from '@spot/shared-utils/fetch.util';

export default async function getApiAnalysisReportService(car_code: string) {
  const url = ['properties', `${car_code}`].join('/');

  return fetch(url, {
    method: 'GET',
    ms: 'ANALYSIS_ESG',
    auth: true,
  });
}
