import { ReportData } from '../constants/report_type';

export function isPRODESInapto(
  item: ReportData['results']['results'][number]
): item is {
  type: 'Desmatamento Ilegal (PRODES)' | 'Desmatamento Zero (PRODES)';
  apto: 'INAPTO';
  area_ha: number;
  area_percentage: number;
  years: string;
} {
  return (
    (item.type === 'Desmatamento Ilegal (PRODES)' ||
      item.type === 'Desmatamento Zero (PRODES)') &&
    item.apto === 'INAPTO'
  );
}

export function isListasInapto(
  item: ReportData['results']['results'][number]
): item is {
  type: 'Lista Suja' | 'Lista Ibama';
  apto: 'INAPTO';
} {
  return (
    (item.type === 'Lista Suja' || item.type === 'Lista Ibama') &&
    item.apto === 'INAPTO'
  );
}

export function isEmbargoConservationAndIndigena(
  item: ReportData['results']['results'][number]
): item is
  | {
      type: 'Embargos do Ibama';
      apto: 'INAPTO';
      area_ha: number;
      area_percentage: number;
      infracoes: string;
    }
  | {
      type: 'Terra Indígena';
      apto: 'INAPTO';
      area_ha: number;
      area_percentage: number;
      fases: string;
    }
  | {
      type: 'Unidades de Conservação';
      apto: 'INAPTO';
      area_ha: number;
      area_percentage: number;
    }
  | {
      type: 'Embargos do Semas';
      apto: 'INAPTO';
      area_ha: number;
      area_percentage: number;
    } {
  return (
    (item.type === 'Terra Indígena' ||
      item.type === 'Embargos do Ibama' ||
      item.type === 'Embargos do Semas' ||
      item.type === 'Unidades de Conservação') &&
    item.apto === 'INAPTO'
  );
}

export function formatDocument(document: string) {
  if (!document) return 'Não consta';
  if (document.length === 11) {
    return `${document.slice(0, 3)}.${document.slice(3, 6)}.${document.slice(
      6,
      9
    )}-${document.slice(9, 11)}`;
  }

  return `${document.slice(0, 2)}.${document.slice(2, 5)}.${document.slice(
    5,
    8
  )}/${document.slice(8, 12)}-${document.slice(12, 14)}`;
}

export function unnormalizeCarCode(carCode: string): string {
  if (!carCode) return 'N/A';

  return (
    carCode.slice(0, 2) +
    '-' +
    carCode.slice(2, 9) +
    '-' +
    carCode.slice(9)
  ).toUpperCase();
}
